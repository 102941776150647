import React from 'react';
import styles from '../styles/cruizPage.module.sass';

export const faqItems = [
  {
    title:
      'Речные круизы на теплоходе в 2025-м – неиссякаемый источник вдохновения для путешественников',
    body: (
      <div className={styles.listFull}>
        Круизы из Москвы или из других российских городов на теплоходе – одно из
        популярных направлений, пользующихся постоянным спросом. Еще бы, ведь
        такие речные круизы по России дают возможность познакомиться со многими
        интересными местами нашей необъятной страны. Компания «Круиз.онлайн»
        предлагает отправиться в увлекательное путешествие на роскошных
        теплоходах в 2025 году.
      </div>
    ),
  },
  {
    title: 'Навигация 2025 года',
    body: (
      <>
        <div className={styles.listFull}>
          Речные круизы на комфортабельном теплоходе – это совершенно новый
          опыт, который наверняка захочется повторить. Вы можете начинать тур из
          столицы или из любого другого города, через который проходит маршрут.
          Может это будет Поволжье, города Большого и Малого Золотого кольца или
          северное направление: Санкт-Петербург, Карелия, Валаам и Кижи,
          Соловецкие острова. Решите для себя, что будет интереснее – выйти в
          воды Белого моря или изучить Прикамье. Не забудьте про длительные и
          грандиозные по объему впечатления водные путешествия по Енисею. Куда
          бы ни звало вас сердце, вы сможете добраться до пункта назначения в
          полной уверенности в собственном комфорте и безопасности.
        </div>
      </>
    ),
  },
  {
    title: 'Условия на борту',
    body: (
      <>
        <div className={styles.listFull}>
          К услугам пассажиров обширный флот из современных, технически
          совершенных и проверенных временем судов. Трех- и четырехпалубные
          красавцы-лайнеры со всеми удобствами от отдельных балконов до бассейна
          на палубе ждут вас, чтобы подарить незабываемые впечатления от туров
          по воде.Вы можете быть уверены, что получите:
        </div>
        <div className={styles.listFullParagraph}>
          <li>
            комфортное размещение в каюте предпочтительного для вас класса;
          </li>
          <li>
            вкусное и разнообразное питание от профессиональных шеф-поваров;
          </li>
          <li>развлекательную программу от команды опытных аниматоров;</li>
          <li>
            широкие возможности отдыха в зависимости от собственных предпочтений
            от тихого чтения в библиотеке, познавательных экскурсий по знаковым
            местам, активных занятий спортом до оздоровительных спа-процедур и
            массажа;
          </li>
          <li>
            туры разнообразной тематики – гастрономические, литературные,
            паломнические и пр.;
          </li>
          <li>
            профессиональное обслуживание, доброжелательность и
            заинтересованность персонала корабля в каждом госте.
          </li>
          Ступая на борт теплохода, пассажиры попадают в совершенно иную
          атмосферу, где властвует тяга к приключениям и открытиям.
        </div>
      </>
    ),
  },
  {
    title: '«Круиз.онлайн»: с нами надежно, удобно и выгодно',
    body: (
      <>
        <div>
          Наша компания предлагает путевки на любой вкус, которые можно купить
          онлайн, без посещения офиса. Здесь собраны все круизы на одном сайте.
          Для того чтобы найти наиболее подходящее для себя предложение,
          понадобится совсем немного времени. Просто используйте фильтры,
          выставляя предпочтительный маршрут, время отправления,
          продолжительность поездки, класс комфорта, и получайте нужный вам
          результат. Изучайте отзывы пассажиров, знакомьтесь с фото- и
          видеоотчетами о путешествиях на судне, в том числе и о детальных
          особенностях кают. Вместе с нашим сервисом бронирования круизов подбор
          круизного тура будет быстрым и легким. Для того чтобы купить путевку
          по приятной цене, вы можете воспользоваться услугой раннего
          бронирования.
          <div className={styles.listFullParagraph}>
            Туры на теплоходах от нашего сервиса бронирования круизов – это
            актуальные предложения и качественный сервис. Вместе с нами вы
            будете в курсе интересных теплоходных туров, выгодных цен. Если у
            вас возникли вопросы, наши опытные консультанты с удовольствием на
            них ответят.
          </div>
        </div>
      </>
    ),
  },
];

export const faqItemsSea = [
  {
    title: 'Путешествие без границ: открываем мир морских круизов',
    body: (
      <div className={styles.listFull}>
        Морские круизы – великолепный вариант незабываемого отпуска, отличная
        возможность посетить несколько стран в весьма комфортных условиях.
        Сервис бронирования круизов «Круиз.онлайн» предлагает купить путевки на
        морские круизы по всему миру. Путешествие на борту роскошного лайнера
        станет отличным отдыхом, яркие впечатления от которого навсегда
        останутся в памяти.
      </div>
    ),
  },
  {
    title: 'Популярные направления',
    body: (
      <>
        <div className={styles.listFull}>
          <strong>Черное море.</strong> Самый популярный вид круизов у россиян.
          Круизы из Сочи на лайнере Astoria Grande выполняются почти круглый
          год, с небольшим перерывом на зимние месяцы. Никаких виз для этих
          круизов не требуется, нужен только загранпаспорт.
          <br />
          <div className={styles.buttonWrapper}>
            <strong>Персидский залив.</strong> Эти круизы чаще всего начинаются
            в Дубае или Абу-Даби и хорошо сочетаются с пляжных отдыхом в
            Арабских Эмиратах. Кроме красоты морских пейзажей, туристов ожидают
            увлекательные экскурсии в городах ОАЭ, Катара, Бахрейна. В
            путешествие лучше отправляться зимой, чтобы избежать летней жары.
            Как правило, российским туристам достаточно загранпаспорта, визы не
            требуются.
            <br />
          </div>
          <div className={styles.buttonWrapper}>
            <strong>Средиземное море.</strong> Великолепный климат манит
            туристов в круизы по морю круглый год, но пик сезона приходится на
            период с конца весны до середины осени. В маршрут могут быть
            включены прибрежные города Италии, Греции, Испании, Франции,
            Черногории, Мальты.
            <br />
          </div>
          <div className={styles.buttonWrapper}>
            <strong>Северная Европа.</strong> Туристов ожидают Балтийское и
            Северное моря, норвежские фьорды и достопримечательности городов
            Швеции, Германии, Великобритании и других стран. Туристический сезон
            длится с апреля по октябрь.
          </div>
        </div>
      </>
    ),
  },
  {
    title: 'Выбирайте путешествие с «Круиз.онлайн»',
    body: (
      <>
        <div className={styles.listFull}>
          На нашем сайте вы можете выбрать морской круиз по разным направлениям.
          Если вам нужна помощь в поиске туристического маршрута, обратитесь за
          консультацией к специалистам компании. Опытный консультант с
          удовольствием ответит на ваши вопросы и поможет сделать оптимальный
          выбор. Забронировать и оплатить выбранный тур можно полностью онлайн,
          без посещения офиса.
        </div>
      </>
    ),
  },
];
